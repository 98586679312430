<template>
  <div>
    <!-- @input="firmsFilter" -->
    <!-- :get-option-label="(option) => option.zcCity" -->

    <CCard>
      <CCardBody class="p-3">
        <div class="row">
          <!-- Country Filter -->
          <div class="col-lg-4 mb-2">
            <v-select
              class="v-select-filter"
              placeholder="Country.."
              :value="country"
              :options="authCountries"
              :reduce="c => c.value"
              :searchable="false"
              @input="countryFilter"
            />
          </div>

          <div class="col-lg mb-2">
            <v-select
              class="v-select-filter"
              placeholder="Please select a region"
              label="zcCity"
              v-model="selected_zip_code"
              :options="allZipCodes"
              :reduce="zc => ({id: zc.id, zcZip: zc.zcZip, zcCity: zc.zcCity})"
              :filter="filteredZipCodes"
              :loading="loading"
              @input="selectingZipCodes"
            >
              <template v-slot:option="zip_code">
                <strong>{{ zip_code.zcZip }}</strong> -
                <span>{{ zip_code.zcCity }} </span>
              </template>
              <span slot="no-options">Sorry, no matching options.</span>
            </v-select>
          </div>

          <div class="col-lg-12 mb-2 mb-lg-0">
            <div class="d-flex">
              <CButton
                size="sm"
                color="link"
                class="p-0 mr-2"
                @click="getZipCodesNearBy"
              >
                <CIcon name="cil-plus" /> Add delivery zones within {{maxDistanceNearBy}} km
              </CButton>

              <CInput
                class="m-0"
                type="range"
                :value.sync="maxDistanceNearBy"
                min="1" max="20" step="1"
              />
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <CDataTable
      class="table-sm table-zip-codes"
      :items="zipCodes"
      :fields="fields"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No linked ZIP codes found!',
      }"
      @row-clicked="(item) => selected_row = item"
    >
      <!-- Distance -->
      <template #distance="{ item }">
        <td class="align-middle">
          <CIcon v-if="item._classes === 'table-warning'" name="cil-warning" size="lg" />
          {{ item.distance | FixedNumber }}
        </td>
      </template>

      <!-- Country -->
      <template #country="{ item }">
        <td class="align-middle">
          <CIcon class="mr-1" :content="$options.flagSet[item.country.flag]" size="lg" />
          {{ item.country.label }}
        </td>
      </template>

      <!-- Zone -->
      <template #zone="{ item }">
        <td class="align-middle">
          <strong>{{ item.zcZip }}</strong>
          <small class="ml-1">{{ item.zcCity }}</small>
        </td>
      </template>

      <!-- Delivery Charge -->
      <template #delivery_charge="{ item }">
        <td class="align-middle">
          <CInput
            class="mb-0"
            :lazy="false"
            :value.sync="item.pivot.delivery_charge"
            placeholder="0.00"
            type="number"
            step="0.01"
            pattern="^\d+(?:\.\d{1,2})?$"
            onblur="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"

            min="0"
            :max="maxDeliveryCharge"
            :was-validated="!item.pivot.valid"
            @input="(value, event) => validateDeliveryCharge(value, item)"
          >
            <template #prepend-content><CIcon name="cil-euro" /></template>
          </CInput>
        </td>
      </template>

      <!-- Minimum Order -->
      <template #minimum_order="{ item }">
        <td class="align-middle">
          <CInput
            class="mb-0"
            :lazy="false"
            :value.sync="item.pivot.minimum_order"
            placeholder="0.00"
            type="number"
            step="0.01"
            pattern="^\d+(?:\.\d{1,2})?$"
            onblur="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"

            min="0"
            :max="maxMinimumOrder"
            :was-validated="!item.pivot.valid"
            @input="(value, event) => validateMinimumOrder(value, item)"
          >
            <template #prepend-content><CIcon name="cil-euro" /></template>
          </CInput>
        </td>
      </template>

      <!-- Free Charge Above -->
      <template #free_above="{ item }">
        <td class="align-middle">
          <CInput
            class="mb-0"
            :lazy="false"
            :value.sync="item.pivot.free_above"
            placeholder="0.00"
            type="number"
            step="0.01"
            pattern="^\d+(?:\.\d{1,2})?$"
            onblur="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"

            :min="(item.pivot.minimum_order > 0 && item.pivot.free_above > 0) ? item.pivot.minimum_order : 0"
            :max="item.pivot.delivery_charge > 0 ? maxFreeAbove : 0"
            :disabled="item.pivot.delivery_charge <= 0"
            :was-validated="!item.pivot.valid"
            @input="(value, event) => validateFreeAbove(value, item)"
          >
            <template #prepend-content><CIcon name="cil-euro" /></template>
          </CInput>
        </td>
      </template>

      <!-- Status -->
      <template #status="{item}">
        <td class="align-middle">
          <CSwitch
            labelOn="ACT"
            labelOff="INA"
            color="success"
            shape="pill"
            :disabled="!item.updatable"
            :checked.sync="item.enabled"
            @update:checked="() => updateAvailability(item)"
          />
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{ item, index }">
        <td class="align-middle">
          <CButton
            size="sm"
            color="danger"
            class="ml-1"
            @click="deleteItem(item, index)"
            :disabled="!item.updatable"
            ><CIcon name="cil-trash"
          /></CButton>
        </td>
      </template>
    </CDataTable>

    <CElementCover :opacity="0.4" v-show="submitted"/>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { flagSet } from '@coreui/icons'
import _debounce from 'lodash/debounce';

export default {
  name: "zip-codes",
  flagSet,
  components: {
    vSelect,
  },
  props: {
    active: { default: false, type: Boolean },
    zip_codes: { default: [], type: Array },
    restaurant_zip_code: { default: null, type: String },
    restaurant_country: { default: 1, type: Number },
    restaurant_lat: { default: null, type: Number },
    restaurant_lng: { default: null, type: Number },
  },
  data() {
    return {
      allZipCodes: [],
      selected_zip_code: null,
      selected_row: null,
      loading: false,
      submitted: false,
      maxDistanceNearBy: 10,

      fields: [
        { key: "distance",        label: "Distance (km)",   filter: false, _style: "min-width: 80px" },
        { key: "country",         label: "Country",         filter: false, _style: "min-width: 120px; width: 15%" },
        { key: "zone",            label: "Delivery Zone",   filter: false, _style: "min-width: 200px; width: 20%" },
        { key: "delivery_charge", label: "Delivery Charge", filter: false, _style: "min-width: 120px; width: 20%" },
        { key: "minimum_order",   label: "Minimum Order",   filter: false, _style: "min-width: 120px; width: 20%" },
        { key: "free_above",      label: "Free Charge Above", filter: false, _style: "min-width: 120px; width: 20%" },
        { key: "status",          label: "Status",          filter: false, _style: "min-width: 96px; width: 96px;" },
        { key: "actions",         label: "Actions",         filter: false, sorter: false, _style: "min-width: 84px; width: 84px;" },
      ],
    };
  },

  watch: {
    active: async function (val) {
      if (val) {
        await Promise.all([this.getAllZipCodes()]);
      }
    },

    restaurant_country: [{
      handler: 'countryFilter'
    }],

    restaurant_zip_code: async function (val) {
      await Promise.all([this.changedRestaurantZipCode(val)]);
    },

    selected_row: {
      handler(val) {
        if (!val) {
          return;
        }

        this.zipCodes
          .filter((zc) => zc.zcZip == val.zcZip && zc.id != val.id )
          .forEach((fe_zc) => {
            fe_zc.pivot.delivery_charge = val.pivot.delivery_charge !== '' ? val.pivot.delivery_charge : 0;
            fe_zc.pivot.free_above = val.pivot.free_above !== '' ? val.pivot.free_above : 0;
            fe_zc.pivot.minimum_order = val.pivot.minimum_order !== '' ? val.pivot.minimum_order : 0;
          });
      },
      deep: true,  // This will let Vue know to look inside the array
      immediate: true //  Also very important the immediate in case you need it, the callback will be called immediately after the start of the observation
    },
  },

  computed: {
    zipCodes: {
      get() {
        return this.zip_codes.map((el) => {
          return {
            ...el,
            country: this.authCountries.find((c) => c.value === el.zcCountryId),
            distance: this.$globalFunc.haversineDistance(
              { lat: this.restaurant_lat, lng: this.restaurant_lng },
              { lat: el.zcLat, lng: el.zcLng }
            ),
          };
        })
        .map((el) => {
          let classes = el.distance > this.maxDistance ? "table-warning" : "";
          classes += el.pivot?.disabled ? " strikeout " : "";

          return {
            ...el,
            enabled: !(el.pivot.disabled),
            updatable: !(el.zcCountryId == this.restaurant_country && el.zcZip == this.restaurant_zip_code),
            _classes: classes,
          };
        });
      },
      set(newValue) {
        this.$emit("update:zip_codes", newValue);
      },
    },
    country() {
      return this.$store.state.filters.country || this.$store.state.countryId
    },
    maxDistance() {
      return process.env.VUE_APP_DELIVERY_ZONE_DISTANCE_WARNING_LIMIT;
    },

    maxDeliveryCharge() { return 50; },
    maxMinimumOrder() { return 150; },
    maxFreeAbove() { return 150; },
    isValid() { return !this.zipCodes.some(item => item.pivot.valid === false); }
  },

  async beforeMount() {
    this.changedRestaurantZipCode = _debounce(async (zip_code) => {
      if (this.restaurant_country != this.country || !this.allZipCodes.length) {
        await Promise.all([this.countryFilter(this.restaurant_country)]);
      }
      await Promise.all([this.addZipCodes(zip_code)]);
    }, 1000);

    await Promise.all([
      // this.getAllZipCodes(),
      this.changedRestaurantZipCode(this.restaurant_zip_code)
    ]);
  },

  methods: {
    async getAllZipCodes() {
      this.allZipCodes = JSON.parse(sessionStorage.getItem(`${this.country}-zip-codes`)) || [];
      if (this.allZipCodes.length) {
        return;
      }

      this.loading = true;
      await this.$axios
        .get(this.$backend.ZIP_CODES.GET_ALL, {
          params: {
            country: this.country,
          },
        })
        .then((response) => (this.allZipCodes = response.data))
        .finally(() => {
          this.loading = false;
          sessionStorage.setItem(`${this.country}-zip-codes`, JSON.stringify(this.allZipCodes));
        });
    },

    deleteItem(item, index) {
      this.zipCodes = this.removeZipCodes(this.zipCodes, item.zcZip, item.zcCountryId);
    },

    removeZipCodes(zip_codes, zip, country) {
      return zip_codes.filter(function (item) {
        return (item.zcZip != zip) || (item.zcCountryId != country);
      });
    },

    filteredZipCodes(options, search) {
      return search.length
        ? options.filter(
            (option) =>
              option.zcZip.includes(search) ||
              option.zcCity.toLowerCase().includes(search.toLowerCase())
          )
        : null;
    },

    selectingZipCodes() {
      if (this.selected_zip_code) {
        this.addZipCodes(this.selected_zip_code.zcZip)
      }
    },

    addZipCodes(zip_code, zip_id) {
      let newZipCodes = [];

      const filteredZipCodes = zip_id
        ? this.allZipCodes.filter((f_zc) => f_zc.id == zip_id)
        : this.allZipCodes.filter((f_zc) => f_zc.zcZip == zip_code);

      filteredZipCodes.forEach((fe_zc) => {
        if (!this.zipCodes.some((i_zc) => i_zc.id == fe_zc.id)) {
          const item = {
            id: fe_zc.id,
            zcCity: fe_zc.zcCity,
            zcLat: fe_zc.zcLat,
            zcLng: fe_zc.zcLng,
            zcZip: fe_zc.zcZip,
            zcCountryId: fe_zc.zcCountryId,
            bounds: fe_zc.bounds,
            // country: this.authCountries.find((c) => c.value === fe_zc.zcCountryId),
            // distance: this.$globalFunc.haversineDistance(
            //   { lat: this.restaurant_lat, lng: this.restaurant_lng },
            //   { lat: fe_zc.zcLat, lng: fe_zc.zcLng }
            // ),
            // _classes: fe_zc.distance > this.maxDistance ? 'table-warning' : '',

            pivot: {
              delivery_charge: 0,
              free_above: 0,
              minimum_order: 0,
              disabled: null,
              valid: true
            },
          };

          newZipCodes.push(item);
        }
      });

      this.zipCodes = this.zipCodes.concat(newZipCodes);
    },

    async getZipCodesNearBy() {
      const vm = this;
      this.submitted = true;

      await this.$axios
        .get(this.$backend.ZIP_CODES.NEAR_BY, {
          params: {
            "restaurant-latitude": this.restaurant_lat,
            "restaurant-longitude": this.restaurant_lng,
            "max-distance": this.maxDistanceNearBy,
          },
        })
        .then((response) => {
          response.data.forEach((zone, index) => {
            setTimeout(() => {
              vm.addZipCodes(zone.zcZip, zone.id);
              // scroll down one page
              window.scrollBy(0, window.innerHeight);
            }, index * 50);
          });
        })
        .finally(() => { this.submitted = false; });
    },

    // Filters
    async countryFilter(value) {
      this.$store.commit("setCountryFilter", value);
      this.selected_zip_code = null;
      await this.getAllZipCodes();
    },

    rangeValidate(item) {
      item.pivot.delivery_charge = Number(item.pivot.delivery_charge);
      item.pivot.minimum_order = Number(item.pivot.minimum_order);
      item.pivot.free_above = Number(item.pivot.free_above);

      return (item.pivot.delivery_charge >= 0 && item.pivot.delivery_charge <= this.maxDeliveryCharge)
        && (item.pivot.minimum_order >= 0 && item.pivot.minimum_order <= this.maxMinimumOrder)
        && (item.pivot.free_above >= 0 && item.pivot.free_above <= this.maxFreeAbove);
    },

    validateDeliveryCharge(value, item) {
      value = Number(value);
      item.pivot.delivery_charge = value;
      let isValid = this.rangeValidate(item);

      isValid &&= value > 0
        ? (item.pivot.free_above >= 0 && item.pivot.free_above <= this.maxFreeAbove)
        : (item.pivot.free_above = 0) == 0;

      item.pivot.valid = isValid;
      return isValid;
    },

    validateMinimumOrder(value, item) {
      value = Number(value);
      item.pivot.minimum_order = value;
      let isValid = this.rangeValidate(item);

      if (value > 0 && item.pivot.free_above > 0) {
        isValid &&= (item.pivot.free_above >= value && item.pivot.free_above <= this.maxFreeAbove)
      }

      item.pivot.valid = isValid;
      return isValid;
    },

    validateFreeAbove(value, item) {
      item.pivot.free_above = Number(value);
      item.pivot.valid = this.validateDeliveryCharge(item.pivot.delivery_charge, item)
        && this.validateMinimumOrder(item.pivot.minimum_order, item);
    },

    // Only called from Restaurant Item
    validate() {
      this.zipCodes.forEach(item => {
        (typeof item.pivot.valid === 'undefined') && this.validateFreeAbove(item.pivot.free_above, item)
      })

      return !this.zipCodes.some(item => item.pivot.valid === false);
    },

    updateAvailability(item) {
      this.zipCodes
        .filter(area => area.zcZip === item.zcZip)
        .forEach(area => area.pivot.disabled = !item.enabled);
    },
  },
};
</script>

<style>
.table-zip-codes th {
  border-top: none;
}
</style>
